* {
	backface-visibility: hidden;
	-webkit-font-smoothing: antialiased;
}

html,
body {
    padding: 0;
    margin: 0;
    background-color: $c-urbio-back;
}

html {
    scroll-behavior: smooth;
    font-size: 15px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
  }


body {
    position: relative;
    font-size: 15px;
    line-height: normal;
    overflow-x: hidden;
    font-family: $Lora;

}



p {
    margin: 0;
}

a {
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

strong {

    font-weight: 700;
}

h1,h2,h3,h4,h5,h6 {
    overflow-wrap: normal;
    font-weight: 700;
    font-family: $Roboto;
}


/* KONTAIERY */

.content {
  //background-color: yellow;
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  @media screen and (max-width: 1080px){
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 780px){

    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: $menuBreak){
    padding-left: 25px;
    padding-right: 25px;
  }
}


.container {
  width: 100%;
  max-width: 1080px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 40px;
  padding-right: 40px;
  @media screen and (max-width: 1080px){
    padding-top: 0px;
    padding-left: 40px;
    padding-right: 40px;
  }
  @media screen and (max-width: 780px){

    padding-left: 30px;
    padding-right: 30px;
  }
  @media screen and (max-width: $menuBreak){
    padding-left: 25px;
    padding-right: 25px;
  }
}

.container__noPadd {
    width: 100%;
    padding-right: 0px;
    padding-left: 0px;
    margin-right: auto;
    margin-left: auto;
    @media only screen and (min-width: 1080px) {
        max-width: 1000px;
        padding-right: 0px;
        padding-left: 0px;
    }
}

.container__basicPad {
    padding-top: 3 * $p-basic;
    padding-bottom: 5 * $p-basic;
    @media only screen and (max-width: 768px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 4 * $p-basic;
    }
    @media only screen and (max-width: 640px) {
        padding-top: 2 * $p-basic;
        padding-bottom: 2 * $p-basic;
    }
}


/* CLEARFIX */

.cf:before,
.cf:after {
    content: ".";
    display: block;
    height: 0;
    overflow: hidden;
}

.cf:after {
    clear: both;
}

.cf {
    zoom: 1;
}

.mb-6 {
    margin-bottom: 6 * $p-basic;
}

.mb-4 {
    margin-bottom: 4 * $p-basic;
}

.mb-3 {
    margin-bottom: 3 * $p-basic;
}

.mb-2 {
    margin-bottom: 2 * $p-basic;
}

.mt-6 {
    margin-top: 6 * $p-basic;
}

.mt-4 {
    margin-top: 4 * $p-basic;
}

.mt-3 {
    margin-top: 3 * $p-basic;
}

.mt-2 {
    margin-top: 2 * $p-basic;
}

.ta-center {
    text-align: center;
}
.hidden {
  display: none;
}

/* MODAL */

.modal {
    position: absolute;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 100;
}

.modal__body {
    width: 100%;
    max-width: 700px;
    margin: (3 * $p-basic) auto (3 * $p-basic) auto;

    padding: 36px 30px 30px 30px;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.16);
    @media screen and (max-width: 560px) {
        padding: 12px 10px 10px 10px;
    }
    position: relative;
}

.modal__close {
    position: absolute;
    right: 30px;
    top: 30px;
    width: 30px;
    height: 30px;
    //background-image: url(../../images/icon_close.svg);
    background-color: transparent;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    border: none;
    outline: none;
    transform: rotate(0Deg);
    @include trans();
    &:hover {
        @include trans();
        transform: rotate(180Deg);
    }
    @media screen and (max-width: 560px) {
        right: 10px;
        top: 10px;
    }
}

.modal__form {
    display: flex;
    margin: 0;
    padding: 0;
    flex-wrap: wrap;
}

.modal__hidden {
    display: none;
}

.modal__content h3 {
    @include typo-body-15;
    text-transform: uppercase;
    margin-bottom: 2 * $p-basic;
}

.modal__content h2 {
    @include typo-head-40;
    text-transform: uppercase;
    margin-bottom: 4.5 * $p-basic;
}

.modal__form input[type="text"],
.modal__form input[type="email"],
.modal__form input[type="tel"] {
    border-radius: $border-radius;
    height: 56px;
    padding: 0px 14px;
    box-sizing: border-box;
    width: 100%;
}

.modal__textarea {
    border-radius: $border-radius;
    height: 120px;
    padding: 14px 14px;
    box-sizing: border-box;
    width: 100%;
}

::-webkit-input-placeholder {
    /* Chrome/Opera/Safari */
    color: rgba($c-urbio-black, 0.40);
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: rgba($c-urbio-black, 0.40);
}

:-ms-input-placeholder {
    /* IE 10+ */
    color: rgba($c-urbio-black, 0.40);
}

:-moz-placeholder {
    /* Firefox 18- */
    color: rgba($c-urbio-black, 0.40);
}

.videoWrapper {
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
}
.videoWrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.topTop {
  position: absolute;
  width: 0px;
  height: 0px;
  left: 0px;
  top: 0px;
}

.scroller {
  position: relative;
  top: -120px;
  width: 0px;
  height: 0px;
  @media screen and (max-width: 1440px){
    top: -120px;
  }
  @media screen and (max-width: 780px){
    top: -110px;
  }
  @media screen and (max-width: $menuBreak){
    top: -110px;
  }
}

.gslide-title {
  margin-bottom: 5px !important;
  font-size: 1.3rem !important;
  overflow-wrap: normal;
    font-weight: 700!important;
    font-family: $Roboto !important;
}

.lg-sub-html {
  max-width: 840px !important;
  margin-left: auto !important;
  margin-right: auto !important;
}
