/* MIXINS */

/* TYPO
* Základní velikost písma v dokumentu: %
* Rozměry vycházející z velikosti písma dokumentu: rem
* Rozměry vycházející z velikosti písma rodiče: em
* Media Queries: em
* Výška řádku: číslem bez jednotky
* Rámečky, dekorace: px
* Typografie podle velikosti okna: vw
*/

@mixin typo-head-40 {

  font-style: normal;
  font-size: 2.22rem;
  line-height: 2.777rem;
  @media screen and (max-width: $menuBreak) {
    font-size: 1.222rem;
    line-height: 1.388rem;
  }
}

@mixin typo-head-20 {

  font-style: normal;
  font-size: 1.33rem;
  line-height:1.666rem;
  @media screen and (max-width: $menuBreak) {
    font-size: 1.06rem;
    line-height: 1.33rem;
  }
}

@mixin typo-body-15 {

  font-style: normal;
  font-weight: normal;
  font-size: 1rem;
  line-height: 1.5rem;
  @media screen and (max-width: $menuBreak)  {
    font-size: 0.877rem;
    line-height: 1.2rem;
  }
}

@mixin shadow($x: 0, $y: 1px, $blur: 2px, $spread: 0, $alpha: 0.25) {
	-webkit-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	-moz-box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
	box-shadow: $x $y $blur $spread rgba(0, 0, 0, $alpha);
}
@mixin trans($t: 0.5s, $type: all) {
	-webkit-transition: $type $t ease;
	-ms-transition: $type $t ease;
	transition: $type $t ease;
}
