/* BUTTONS */

.btn__red {
  display: inline-block;
  padding: 7px 15px 7px 15px;

  border-radius: 20px;
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  width: auto;
  @include trans();
  &:hover {
    @include trans();
  }
}
