/* TOP */
@import "../../variables";

/*
background-image: url('./images/top_img.jpg');
@media screen and (max-width: 1024px) {
    padding: 170px 0px 210px 0px;
  }
*/
.carousel {
  background-color: $c-urbio-white;
  padding: 115px 0px 150px 0px;
  @media screen and (max-width: 990px) {
    padding: 90px 0px 120px 0px;
  }
  @media screen and (max-width: 560px) {
    padding: 40px 0px 20px 0px;
  }
}

.carousel_txt {
  float: left;
  margin-right: 60px;
  font-size: 1.2rem;
  line-height: 1.6rem;
  width: calc(50% - 30px);
  @media screen and (max-width: 768px) {
    width: calc(50% - 10px);
  }
  @media screen and (max-width: 560px) {
    float: none;
    width: 100%;
  }
}

.carousel_txt h2 {
  text-transform: uppercase;
  font-size: 1.87rem;
  line-height: normal;
  margin-bottom: 30px;
}

.carousel_img {
  width: calc(50% - 30px);
  float: right;
  @media screen and (max-width: 768px) {
    width: calc(40% - 10px);
  }
  @media screen and (max-width: 560px) {
    float: none;
    width: 100%;
  }
}

.carousel .tns-outer {
    position: relative;
}
.carousel .tns-nav {
    position: absolute;
    bottom: -115px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 560px) {
      display: none;
    }
}
.carousel .tns-nav button {
    width: 95px;
    height: 95px;
    border: none;
    background-color: rgba($c-urbio-black,0.2);
    position: relative;
    @media screen and (max-width: 990px) {
      width: 65px;
      height: 65px;
    }
    @media screen and (max-width: 650px) {
      width: 50px;
      height: 50px;
    }
}

.carousel .tns-nav button::before {
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  filter: grayscale(100%);
}

.carousel .tns-nav button.tns-nav-active::before {
  filter: grayscale(0%);
}

.carousel .tns-nav button[data-nav="0"]::before {
  background-image: url('./images/carousel_1_t.png');
}
.carousel .tns-nav button[data-nav="1"]::before {
  background-image: url('./images/carousel_2_t.png');
}
.carousel .tns-nav button[data-nav="2"]::before {
  background-image: url('./images/carousel_3_t.png');
}
.carousel .tns-nav button[data-nav="3"]::before {
  background-image: url('./images/carousel_4_t.png');
}
.carousel .tns-nav button[data-nav="4"]::before {
  background-image: url('./images/carousel_5_t.png');
}
.carousel .tns-nav button[data-nav="5"]::before {
  background-image: url('./images/carousel_6_t.png');
}
.carousel .tns-nav button[data-nav="6"]::before {
  background-image: url('./images/carousel_7_t.png');
}
.carousel .tns-nav button[data-nav="7"]::before {
  background-image: url('./images/carousel_8_t.png');
}

.carousel .tns-controls {
  position: absolute;
  left: 0px;
  width: 100%;
  top: calc(50% - 25px);
  z-index: 20;
  display: none;
  @media screen and (max-width: 560px) {
    display: block;
  }
}
.carousel .tns-controls button {
  position: absolute;
  opacity: 0.2;
  top: 0px;
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url('./images/carousel_next.svg');
  background-size: 50px 50px;
  background-position: center;
  @include trans();
  &:hover {
    @include trans();
    opacity: 0.8;
  }
}
.carousel .tns-controls button[data-controls="prev"] {
  left: 0px;
  transform: rotate(180deg);
}
.carousel .tns-controls button[data-controls="next"] {
  right: 0px;

}
.carousel button[data-action="stop"], .carousel button[data-action="start"] {
  display: none;
}


