/* HEADER */
@import "../../variables";

.nav {
  position: fixed;
  width: 100%;
  top: 0px;
  left: 0px;
  @include trans();
  padding-top: 24px;
  z-index: 100;
  @media screen and (max-width: 720px) {
    padding-top: 16px;
  }
  @media screen and (max-width: $menuBreak) {
    padding-top: 8px;
  }
}


.nav__inner {
  display: flex;
  align-items: center
}

.nav__logo {
  width: 154px;
  height: 120px;
  @include trans();
  @media screen and (max-width: 720px) {
    width: 100px;
    height: 78px;
  }
  @media screen and (max-width: 450px) {
    width: 88px;
    height: 68px;
  }
}

.nav__mobile__holder {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  position: relative;

  @media screen and (max-width: $menuBreak) {
    flex: none;
    position: absolute;
    right: -150px;
    top: 20px;
    width: 150px;
    padding: 0px 20px 0px 20px;
    background-color: $c-urbio-black;
    @include trans();
  }

}




.nav__mobile__toggleBtn {
  width: 28px;
  height: 29px;
  background-image: url('./images/menu_open.svg');
  background-size: 29px 29px;
  background-position: center center;
  background-repeat: no-repeat;
  border: none;
  outline: none;
  border-radius: 0px;
  position: absolute;
  left: -44px;
  display: none;
  @media screen and (max-width: $menuBreak) {
    display: block;
  }
}


.nav__mobile__holder.open {
  right: 20px;
  @include trans();
}

.nav__mobile__holder.open .nav__mobile__toggleBtn {
  background-image: url('./images/menu_close.svg');
}



.nav_menu {
  list-style-type: none;
  margin: 0px;
  padding: 0px;
  display: inline-block;
}


.nav_menu li {
  float: left;
  margin-left: 20px;
  @media screen and (max-width: $menuBreak) {
    margin-left: 0px;
    display: block;
    width: 100%;
  }
}

.nav_menu li a {
  display: block;
  font-family: $Roboto;
  font-weight: 700;
  font-size: 1.15rem;
  text-decoration: none;
  text-transform: uppercase;
  color: $c-urbio-black;
  @include trans();
  @media screen and (max-width: $menuBreak) {
    text-align: center;
    color: $c-urbio-white;
    font-size: 0.933rem;
    padding: 16px 0px 16px 0px;
    border-bottom: 1px solid #3C3C3C;
  }
}

.nav_menu li.active a, .nav_menu li a:hover {
  color: $c-urbio-brown;
  @include trans();
}

.scrolled {
  padding-top: 10px;
  background-image:  url('./images/top_sub.jpg');
  background-size: cover;
  background-position: center center;
  box-shadow: 0px 4px 16px -2px rgba(0,0,0,0.43);
  @include trans();

}
.scrolled .nav__logo {
  width: 88px;
  height: 68px;
  @include trans();
}

