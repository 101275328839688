/* TOP */
@import "../../variables";

/*
background-image: url('./images/top_img.jpg');
@media screen and (max-width: 1024px) {
    padding: 170px 0px 210px 0px;
  }
*/
.realization {
  padding: 220px 0px 260px 0px;
  @media screen and (max-width: 1024px) {
    padding: 170px 0px 210px 0px;
  }
  @media screen and (max-width: 650px) {
    padding: 128px 0px 20px 0px;
  }
}
.realization h1 {
  margin-bottom: 0px;

}
.realization h2 {
  margin-top: 30px;
  margin-bottom: 20px;

}
.realization_items {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  gap: 15px 15px;
  padding-bottom: 0px;
  @media screen and (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    margin-top: 0px;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
}

.realization_item {
    background-color: rgba($c-urbio-white,1);
    color: $c-urbio-txt;
    padding: 10px;
    display: block;
    text-decoration: none;
    @include trans();
    &:hover {
      background-color: rgba($c-urbio-white,0.6);
      @include trans();
    }
}

.realization_item img {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.47rem;
}

.realization_item h2 {
  display: inline;
  font-weight: bold;
  font-size: 1rem;
  line-height: 1.47rem;
  font-family: $Lora;
}

.realization .noShow {
  display: block;
}
