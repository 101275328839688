/* lora-regular - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 400;
  src: url('../../fonts/lora-v25-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/lora-v25-latin-ext_latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lora-v25-latin-ext_latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/lora-v25-latin-ext_latin-regular.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/lora-v25-latin-ext_latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lora-v25-latin-ext_latin-regular.svg#Lora') format('svg'); /* Legacy iOS */
}
/* lora-700 - latin-ext_latin */
@font-face {
  font-family: 'Lora';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/lora-v25-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/lora-v25-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/lora-v25-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/lora-v25-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/lora-v25-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/lora-v25-latin-ext_latin-700.svg#Lora') format('svg'); /* Legacy iOS */
}

/* roboto-700 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  src: url('../../fonts/roboto-v30-latin-ext_latin-700.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v30-latin-ext_latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v30-latin-ext_latin-700.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v30-latin-ext_latin-700.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v30-latin-ext_latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v30-latin-ext_latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-900 - latin-ext_latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  src: url('../../fonts/roboto-v30-latin-ext_latin-900.eot'); /* IE9 Compat Modes */
  src: local(''),
       url('../../fonts/roboto-v30-latin-ext_latin-900.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('../../fonts/roboto-v30-latin-ext_latin-900.woff2') format('woff2'), /* Super Modern Browsers */
       url('../../fonts/roboto-v30-latin-ext_latin-900.woff') format('woff'), /* Modern Browsers */
       url('../../fonts/roboto-v30-latin-ext_latin-900.ttf') format('truetype'), /* Safari, Android, iOS */
       url('../../fonts/roboto-v30-latin-ext_latin-900.svg#Roboto') format('svg'); /* Legacy iOS */
}
