/* TOP */
@import "../../variables";
.aboutus_top_img {

  height: 70vh;
  object-fit: cover;
  width: 100%;

  @media screen and (max-width: 990px) {
    height: 60vh;
  }
  @media screen and (max-width: 650px) {
    height: 40vh;
  }
}
.aboutus {
    padding-top: 50px;
    padding-bottom: 120px;
    @media screen and (max-width: 90px) {
      padding-top: 40px;
    padding-bottom: 80px;
    }
    @media screen and (max-width: 650px) {
      padding-top: 30px;
      padding-bottom: 30px;
    }
}
.aboutus h1 {
    margin-bottom: 30px;

}

.aboutus p {
  margin-bottom: 30px;

}
.aboutus {
  font-size: 1.2rem;
  line-height: 1.8rem;
}
.aboutus p:last-child {
  margin-bottom: 0px;
}
.aboutus li {
  margin-bottom: 10px;
}
.aboutus ul {
  margin-bottom: 30px;
}
.aboutus_narrow {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 70%;
  font-size: 1.5rem;
  line-height: 2.4rem;
}
