/* VARIABLES */
/* FONTS */
$Lora: 'Lora','Calisto MT', 'Bookman Old Style', Bookman, 'Goudy Old Style', Garamond, 'Hoefler Text', 'Bitstream Charter', Georgia, serif;
$Roboto: 'Roboto', helvetica, arial, sans-serif;

/* COLORS */
$c-urbio-blue: rgba(41,48,65,1); //#293041
$c-urbio-txt: rgba(41,48,65,1); //#293041
$c-urbio-red: rgba(159,70,86,1); //#9F4656
$c-urbio-yellow: rgba(235,224,189,1); //#EBE0BD
$c-urbio-green: rgba(64,178,153,1); //#40B299
$c-urbio-grey: rgba(41,48,65,1); //#293041
$c-urbio-black: rgba(0,0,0,1); //#000000
$c-urbio-white: rgba(255,255,255,1); //#FFFFFFF
$c-urbio-brown: rgba(119,34,6,1); //#772206
$c-urbio-light: rgba(228,188,128,1); //#E4BC80
$c-urbio-back: rgba(251,242,225,1); //#FBF2E1



$c-grey-link: rgba(112,112,112,1); //#707070

$menuBreak: 1000px;

/* border-radius */
$border-radius: 4px;

/* paddings */
$p-basic: 10px;

/* widths */
$w-max: 1270px;



/* widths */
//basic height for button, and inputs
$h-basic: 40px;

/* annimation */
$ct-ease-swift: cubic-bezier(0, 0.1, 0.3, 1);
$ct-ease-ease-out: cubic-bezier(0, 0, 0.3, 1);
$ct-ease-ease-in-out: cubic-bezier(0.1, 0, 0.2, 1);
