/* TOP */
@import "../../variables";

.top {
  background-image: url('./images/top_img.jpg');
  background-size: cover;
  background-position: center;
  padding: 220px 0px 260px 0px;
  @media screen and (max-width: 1024px) {
    padding: 170px 0px 210px 0px;
  }
  @media screen and (max-width: 650px) {
    padding: 128px 0px 0px 0px;
    background-size: auto 60%;
    background-position: top center;
  }
  @media screen and (max-width: 460px) {
    background-position: top left -100px;
  }

}

.top_content {
  position: relative;
}

.top_stamp {

    position: absolute;
    bottom: -130px;
    right: 10px;
    @media screen and (max-width: 650px) {
      position: relative;
      bottom: 0px;
      right: 0px;
      margin-left: auto;
      display: block;
      margin-bottom: 20px;
    }
}

.top_txt {
    width: 100%;
    max-width: 520px;
    font-family: $Lora;
    font-weight: bold;
    font-size: 1.33rem;
    line-height: 2rem;
    margin-left: auto;
    padding-right: 40px;
    @media screen and (max-width: 900px) {
      max-width: none;
      width: 60%;

    }
    @media screen and (max-width:780px) {
      padding-right: 30px;
    }
    @media screen and (max-width: 650px) {
      width: 100%;
      background-color: $c-urbio-back;
      padding-top: 15px;
      padding-bottom: 15px;
      padding-right: 25px;
      padding-left: 25px;
    }
}

.top_items_wrap {
  margin-top: -60px;
  margin-bottom: 35px;
  @media screen and (max-width: 650px) {
    margin-top: 0px;

  }
}

.top_items {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-auto-rows: auto;
  gap: 15px 15px;
  padding-bottom: 0px;
  @media screen and (max-width: 650px) {
    margin-top: 0px;
  }
  @media screen and (max-width: 560px) {
    grid-template-columns: 1fr;
  }
}

.top_item {
    background-color: rgba($c-urbio-white,1);
    color: $c-urbio-txt;
    padding: 10px;
    display: block;
    text-decoration: none;
    @include trans();
    &:hover {
      background-color: rgba($c-urbio-white,0.6);
      @include trans();
    }
}

.top_item img {
  width: 100%;
  margin-bottom: 10px;
  font-size: 1rem;
  line-height: 1.47rem;
}

.top_item h2 {
  display: block;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: normal;
  font-family: $Roboto;
  margin-bottom: 5px;
}
