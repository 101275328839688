/* FOOTER */
@import "../../variables";

.foot {
  background-color: $c-urbio-light;
  padding-top: 60px;
}
.foot_txt {

  padding-bottom: 60px;

}


.foot_txt_wrap {
  display: flex;
  @media only screen and (max-width: 650px) {
    display: block;
  }
}
.foot_txt_wrap:first-child {
  margin-bottom: 90px;
  @media only screen and (max-width: 990px) {
    margin-bottom: 60px;
  }
  @media only screen and (max-width: 650px) {
    margin-bottom: 30px;
  }
}
.foot_txt_wrap img {
  margin-right: 135px;
  @media only screen and (max-width: 990px) {
    margin-right: 60px;
  }
  @media only screen and (max-width: 650px) {
    margin-bottom: 30px;
    margin-right: 0px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
}

.foot_txt_txt {
  flex: 1;
  @media only screen and (max-width: 650px) {
    flex: none;
  }
}


.foot_txt_wrap_round {
  border-radius: 50%;
  width: 240px;
  height: 240px;
}
.foot_txt_wrap_noround {
  width: 240px;
  height: 240px;
}

.foot_txt_txt h2 {
  font-size: 1.87rem;
  line-height: normal;
  margin-bottom: 32px;
}
.foot_txt_txt h3 {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 0px;
  text-transform: uppercase;
  margin-bottom: 5px;
  @media only screen and (max-width: 460px) {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}
.foot_txt_txt p {
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 32px;
  @media only screen and (max-width: 460px) {
    font-size: 1.1rem;
    line-height: 1.4rem;
  }
}
.foot_txt_txt p:last-child {
  margin-bottom: 0px;
}
.foot_txt_txt a {
  color: $c-urbio-black;
  text-decoration: underline;
}

.foot_bottom {
  padding-top: 25px;
  padding-bottom: 25px;
  background-color: $c-urbio-brown;
  text-align: center;
}

.foot_bottom a {
  cursor: pointer;
  display: inline-block;
  @include trans();
  &:hover {
    @include trans();
    .foot_social_icon {
      @include trans();
      fill: $c-urbio-yellow;
    }
  }
}
.foot_bottom a:first-child {
  margin-right: 40px;
}
.foot_social_icon {
  @include trans();
  fill: #fff;
  cursor: pointer;
}
