/* TOP */
@import "../../variables";

/*
background-image: url('./images/top_img.jpg');
@media screen and (max-width: 1024px) {
    padding: 170px 0px 210px 0px;
  }
*/
.realizationHP {
  padding: 65px 0px 65px 0px;
  @media screen and (max-width: 990px) {
    padding: 50px 0px 50px 0px;
  }
  @media screen and (max-width: 560px) {
    padding: 30px 0px 30px 0px;
  }
}

.realizationHP h2 {
    font-size: 1.87rem;
    text-transform: uppercase;
    margin-bottom: 0px;

}
.realizationHP h3 {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 35px;
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
}

.realizationHP h3 {
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 20px;
}

.carouselB_item {
  color: $c-urbio-txt;
  text-decoration: none;
  cursor: pointer;
  @include trans();
  &:hover {
    color: $c-urbio-brown;
    @include trans();
  }
}

.carouselB_item img {
  width: 100%;
  display: block;
  margin-bottom: 20px;
  @media screen and (max-width: 768px) {
    margin-bottom: 10px;
  }
}

.realizationHP .carouselB_noshow {
  display: none !important;
}

.realizationHP .tns-outer {
    position: relative;
}
.realizationHP .tns-nav {
  display: none;
}
.realizationHP .tns-controls {
  position: absolute;
  left: 0px;
  width: 100%;
  top: calc(32%);
  z-index: 20;
  display: block;

}
.realizationHP .tns-controls button {
  position: absolute;
  opacity: 0.4;
  top: 0px;
  width: 50px;
  height: 50px;
  border: none;
  background-color: transparent;
  background-image: url('./images/carousel_next.svg');
  background-size: 50px 50px;
  background-position: center;
  @include trans();
  &:hover {
    @include trans();
    opacity: 0.8;
  }
}
.realizationHP .tns-controls button[data-controls="prev"] {
  left: 0px;
  transform: rotate(180deg);
}
.realizationHP .tns-controls button[data-controls="next"] {
  right: 0px;

}
.realizationHP button[data-action="stop"], .realizationHP button[data-action="start"] {
  display: none;
}
.realization_houses {
  background-image: url('./images/houses_down.jpg');
  background-position: center center;
  background-size: cover;
  height: 650px;
  width: 100%;
  @media screen and (max-width: 1080px) {
    height: 60vw;
  }
}

.realization_link {
  display: block;
  font-size: 1.2rem;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 35px;
  color: $c-urbio-black;
  text-decoration: underline;
  font-weight: 700;
  font-family: $Roboto;
  @include trans();
  @media screen and (max-width: 768px) {
    margin-bottom: 15px;
  }
  &:hover {
    @include trans();
    color: $c-urbio-brown;
  }
}

